import { color } from "./common"

export const allProjects = [
    {
        name: 'HMA',
        image: 'hma.png',
        background: '#AFECB2',
        id: 'hma'
    },
    {
        name: 'QNVERT',
        image: 'qnvert.png',
        background: '#F0533F',
        id: 'qnvert'
    }
]

export const allBrands = [
    {
        brand: 'biotrips.svg',
        id:'Biotrips',
        testimonial: {
            name: 'Saurabh',
            content: 'We are part agency, part consultancy. Like some kind of a mythical creature. But with a process that makes your brand work harder and your teams work better.',
            image: ''
        }
    },
    {
        brand: 'CRATECAFFIENATE.svg',
        id:'CRATE CAFFIENATE',
        testimonial: {
            name: 'Saurabh',
            content: 'We are part agency, part consultancy. Like some kind of a mythical creature. But with a process that makes your brand work harder and your teams work better.',
            image: ''
        }
    },
    {
        brand: 'sanron.svg',
        id:'Sanron',
        testimonial: {
            name: 'Saurabh',
            content: 'We are part agency, part consultancy. Like some kind of a mythical creature. But with a process that makes your brand work harder and your teams work better.',
            image: ''
        }
    },
    {
        brand: 'hexa-tp.svg',
        id:'Hexa-TP',
        testimonial: {
            name: 'Saurabh',
            content: 'We are part agency, part consultancy. Like some kind of a mythical creature. But with a process that makes your brand work harder and your teams work better.',
            image: ''
        }
    },
    {
        brand: 'augmentor.svg',
        id:'iAugmentor',
        testimonial: {
            name: 'Saurabh',
            content: 'We are part agency, part consultancy. Like some kind of a mythical creature. But with a process that makes your brand work harder and your teams work better.',
            image: ''
        }
    },
    {
        brand: 'tulas.svg',
        id:'Tulas',
        testimonial: {
            name: 'Saurabh',
            content: 'We are part agency, part consultancy. Like some kind of a mythical creature. But with a process that makes your brand work harder and your teams work better.',
            image: ''
        }
    },
]

export const allNewses = [
    {
        date: 'Nov 30, 2020',
        title: 'Hot black hottest office tunes',
        category: 'Spotify',
        img: ''
    },
    {
        date: 'Nov 30, 2020',
        title: 'Hot black hottest office tunes',
        category: 'Spotify',
        img: ''
    }
]


export const team = [
    {
        name: 'Nikhil',
        role: 'Backend Developer',
        quote: '',
        image: 'nikhil.png'
    },
    {
        name: 'Danish',
        role: 'Quality Assurance',
        quote: '',
        image: 'Danish.jpg'
    },
    {
        name: 'Anurag',
        role: 'Frontend Developer',
        quote: 'Technology is best when it brings people together.',
        image: 'ANU_1906.jpg'
    },
    {
        name: 'Neelkanth',
        role: 'Backend Developer',
        quote: 'Beware the easy path. Knowledge grows only through challenge.',
        image: 'Neelkanth.jpg'
    },
    {
        name: 'Khushi',
        role: 'Human Resource',
        quote: 'Welcome each day with a genuine smile from within.',
        image: 'Khushi.jpg'
    },
    {
        name: 'Saumya',
        role: 'Marketing',
        quote: 'It does not matter how slowly you go as long as you do not stop.-Confucius',
        image: 'Saumya.jpg'
    },    
    {
        name: 'Dillan',
        role: 'Sales',
        quote: '',
        image: 'PicsArt_05-14-07.10.58.jpg'
    },    
    {
        name: 'Bondu',
        role: 'Content Writer',
        quote: '',
        image: 'bondu.jpeg'
    },    
    {
        name: 'Bharat',
        role: 'Finance',
        quote: '',
        image: 'bharat.png'
    },
    {
        name: 'Prakhar',
        role: 'Co-founder',
        quote: 'The only way we reach our goals is together!',
        image: 'prakhar.png'
    },
    {
        name: 'Sumbul',
        role: 'CEO',
        quote: '',
        image: ''
    },
]


export const allServices = {
    first: [
        'Enterprise Software',
        'Development & Maintenance',
        'AI & Machine Learning',
        'Research & Design'
    ],
    second: [
        'Virtual Realtity',
        'User Experience Design',
        'Full-Cycle App Development',
        'Wearables'
    ],
    third: [
        'Enterprise Software',
        'Responsive Web Applications',
        'Product Reseach & Launch',
        'Quality Assurance'
    ],
    fourth: [
        'Server & DevOps',
        'AR & VR Applications',
        'User Interface Design',
        'Data Analytics'
    ],
}

export const clients = {
    first: [
        {
            color: '/assets/images_new/clients/mantis_logo.png',
            gray: '/assets/images_new/clients/mantis_logo-modified.png',
        },
        {
            color: '/assets/images_new/clients/bromax.png',
            gray: '/assets/images_new/clients/bromax-modified.png',
        },
        {
            color: '/assets/images_new/clients/betelaffaire.png',
            gray: '/assets/images_new/clients/betelaffaire-modified.png',
        },
        {
            color: '/assets/images_new/clients/iptse.png',
            gray: '/assets/images_new/clients/iptse-modified.png',
        },
        {
            color: '/assets/images_new/clients/hip.png',
            gray: '/assets/images_new/clients/hip-modified.png',
        },
        {
            color: '/assets/images_new/clients/irctc_logo_1.png',
            gray: '/assets/images_new/clients/irctc_logo_1-modified.png',
        },
        {
            color: '/assets/images_new/clients/adaniLogo.png',
            gray: '/assets/images_new/clients/adaniairport-modified.png',
        },
        {
            color: '/assets/images_new/clients/biotrips.svg',
            gray: '/assets/images_new/clients/biotripsGray.svg',
        },
        {
            color: '/assets/images_new/clients/CRATECAFFIENATE.svg',
            gray: '/assets/images_new/clients/CRATECAFFIENATEGray.svg',
        },
        {
            color: '/assets/images_new/clients/augmentor.svg',
            gray: '/assets/images_new/clients/augmentorGray.svg',
        },
        {
            color: '/assets/images_new/clients/hexa-tp.svg',
            gray: '/assets/images_new/clients/hexa-tpGray.svg',
        },
        {
            color: '/assets/images_new/clients/sanron.svg',
            gray: '/assets/images_new/clients/sanronGray.svg',
        },
        {
            color: '/assets/images_new/clients/tulas.svg',
            gray: '/assets/images_new/clients/tulasGray.svg',
        },
        {
            color: '/assets/images_new/clients/fortune.png',
            gray: '/assets/images_new/clients/fortuneGray.png',
        },
        {
            color: '/assets/images_new/clients/viho.png',
            gray: '/assets/images_new/clients/vihoGray.png',
        },
        {
            color: '/assets/images_new/clients/qnvert.png',
            gray: '/assets/images_new/clients/qnvertGray.png',
        },
        {
            color: '/assets/images_new/clients/phronesis.png',
            gray: '/assets/images_new/clients/phronesisGray.png',
        },
        {
            color: '/assets/images_new/clients/indiaa.png',
            gray: '/assets/images_new/clients/indiaaGray.png',
        },
        {
            color: '/assets/images_new/clients/scottEdil.png',
            gray: '/assets/images_new/clients/scottEdilGray.png',
        },
        {
            color: '/assets/images_new/clients/hexabull.png',
            gray: '/assets/images_new/clients/hexabullGray.png',
        },
        {
            color: '/assets/images_new/clients/chargebizz.png',
            gray: '/assets/images_new/clients/chargebizzGray.png',
        },
        {
            color: '/assets/images_new/clients/contentTalks.png',
            gray: '/assets/images_new/clients/contentTalksGray.png',
        },
        {
            color: '/assets/images_new/clients/financeTree.png',
            gray: '/assets/images_new/clients/financeTreeGray.png',
        }
        // {
        //     color: '/assets/images_new/clients/tulasa.svg',
        //     gray: '/assets/images_new/clients/Gray.svg',
        // },
    ],
    second: [
        {
            color: '/assets/images_new/clients/augmentor.svg',
            gray: '/assets/images_new/clients/augmentorGray.svg',
        },
        {
            color: '/assets/images_new/clients/biotrips.svg',
            gray: '/assets/images_new/clients/biotripsGray.svg',
        },
        {
            color: '/assets/images_new/clients/CRATECAFFIENATE.svg',
            gray: '/assets/images_new/clients/CRATECAFFIENATEGray.svg',
        },
        {
            color: '/assets/images_new/clients/hexa-tp.svg',
            gray: '/assets/images_new/clients/hexa-tpGray.svg',
        },
        {
            color: '/assets/images_new/clients/sanron.svg',
            gray: '/assets/images_new/clients/sanronGray.svg',
        },
        {
            color: '/assets/images_new/clients/tulas.svg',
            gray: '/assets/images_new/clients/tulasGray.svg',
        },
    ]
}

export const allCountries =[
    // {
    //     name:'UAE',
    //     address:''
    // },
    {
        name:'IND',
        address:'Plot No 415, MG Road, <br>Ghitorni, New Delhi 110030'
    },
    {
        name:'GER',
        address:'Kapellstr. 2a, <br> Dusseldorf, Germany 40479'
    }
]


export const allVerticals = [
  'Medical',
  'Retail',
  'Technology',
  'House',
  'Real Estate',
  'Medical',
  'Retail',
  'Technology',
  'House',
  'Real EstateMedical',
  'Retail',
  'Technology',
  'House',
  'Real Estate',
]

export const teamImages = [
    {
        image:'secondary-hero/IMG_20191224_182514.jpg',
        class:'col-md-4'
    },
    {
        image:'secondary-hero/IMG_20191007_181116__01.jpg',
        class:'col-md-8'
    },{
        image:'secondary-hero/dillan-2.jpeg',
        class:'col-md-3'
    }
    ,{
        image:'secondary-hero/IMG_2064.jpeg',
        class:'col-md-5'
    },
    {
        image:'secondary-hero/bondhu-2.jpeg',
        class:'col-md-2'
    },
    {
        image:'secondary-hero/khushi-2.jpeg',
        class:'col-md-2'
    }
]

export const allPillars = [
    {
        title:'EMPATHY IN ACTION',
        content:'We are part agency, part consultancy. Like some kind of a mythical creature. But with a proces that makes your brand work harder and your teams work better. We are part agency, part consultancy. Like some kind of a mythical creature. But with a proces that makes your brand work harder and your teams work better ',
        icon:'empathy.svg',
        color:color.redColor,
        active:true,
        index:0
    },
    {
        title:'ALL OVER EGO',
        content:'We are part agency, part consultancy. Like some kind of a mythical creature. But with a proces that makes your brand work harder and your teams work better. We are part agency, part consultancy. Like some kind of a mythical creature. But with a proces that makes your brand work harder and your teams work better ',
        icon:'ego.svg',
        color:color.yellowColor,
        index:1
    },
    {
        title:'OWN THE WORK',
        content:'We are part agency, part consultancy. Like some kind of a mythical creature. But with a proces that makes your brand work harder and your teams work better. We are part agency, part consultancy. Like some kind of a mythical creature. But with a proces that makes your brand work harder and your teams work better ',
        icon:'own-work.svg',
        color:color.greenColor,
        index:2
    },
    {
        title:'STRIVE FOR EXCELLENCE',
        content:'We are part agency, part consultancy. Like some kind of a mythical creature. But with a proces that makes your brand work harder and your teams work better. We are part agency, part consultancy. Like some kind of a mythical creature. But with a proces that makes your brand work harder and your teams work better ',
        icon:'excellence.svg',
        color:color.blueColor,
        index:3
    }
]


export const ourHistory = [
    {
        year:'2016',
        title:'Started our First Project',
        image:'started.png'
    },
    {
        year:'2017',
        title:'Vaishali Office',
        image:'office.png'
    },
    {
        year:'2018',
        title:'Completed 20 Successful Projects',
        image:'completed-projects.png'
    },
    {
        year:'2019',
        title:'Gurgaon Office',
        image:'office.png'
    },
    {
        year:'2020',
        title:'Completed 100 Successful Projects',
        image:'completed-projects.png'
    },
    {
        year:'2021',
        title:'Germany Office',
        image:'office.png'
    }
]


export const serviceDetails = [
    {
        id:'react-native',
        title:'React Native App Development',
        subHeading:'A deliberately diverse collective of different outlooks, backgrounds, disciplines and opinions. From the big pictures to the small details, we don’t do things by half.',
        ecosystem:{
            title:'React Native ecosystem. Choose the sure thing.',
            para:'We are a passionate and ambitious set, moving the world forward by challenging all that’s comfortable, creating ideas that live in culture, and forging profound connect.',
        },
        approach:{
            title:'We choose React Native as our go to approach for cross-platform development',
            para:'We are a passionate and ambitious set, moving the world forward by challenging all that’s comfortable, creating ideas that live in culture, and forging profound connect.. We are a passionate and ambitious set, moving the world forward by challenging all that’s comfortable, creating ideas that live in culture, and forging profound connect. We are a passionate and ambitious set, moving the world forward by challenging all that’s comfortable, creating ideas that live in culture, and forging profound connect.',
            features:[
                {
                    title:'Synchronus API',
                    para:'Vaishali OfficePeople all over the globe are living digitally as the lines between physical and digital are converging. It’s fundamental that companies have world-c. Vaishali OfficePeople all over the globe are living digitally as the lines between physical and digital are converging. It’s fundamental that companies have world-c.',
                    icon:'syncronous-api.svg'
                },
                {
                    title:'Fast Performance',
                    para:'Vaishali OfficePeople all over the globe are living digitally as the lines between physical and digital are converging. It’s fundamental that companies have world-c. Vaishali OfficePeople all over the globe are living digitally as the lines between physical and digital are converging. It’s fundamental that companies have world-c.',
                    icon:'fast-performance.svg'
                },
                {
                    title:'Smooth Development',
                    para:'Vaishali OfficePeople all over the globe are living digitally as the lines between physical and digital are converging. It’s fundamental that companies have world-c. Vaishali OfficePeople all over the globe are living digitally as the lines between physical and digital are converging. It’s fundamental that companies have world-c.',
                    icon:'re.svg'
                }
            ]
        },
        reason:{
            title:'Reasons To Choose React Native',
            para:'We are a passionate and ambitious set, moving the world forward by challenging all that’s comfortable, creating ideas that live in culture, and forging profound connect.. We are a passionate and ambitious set, moving the world forward by challenging all that’s comfortable, creating ideas that live in culture, and forging profound connect. We are a passionate and ambitious set, moving the world forward by challenging all that’s comfortable, creating ideas that live in culture, and forging profound connect.',
            features:[
                {
                    title:'Blazing Fast Performance and Speed',
                    icon:'blazing.svg',
                    para:'Vaishali OfficePeople all over the globe are living digitally as the lines between physical and digital are converging. It’s fundamental that companies have world-c. Vaishali OfficePeople all over the globe  sdfsdffewfew  wefwerew '
                },
                {
                    title:'Reusable Code',
                    icon:'reusable-code.svg',
                    para:'Vaishali OfficePeople all over the globe are living digitally as the lines between physical and digital are converging. It’s fundamental that companies have world-c. Vaishali OfficePeople all over the globe  sdfsdffewfew  wefwerew '
                },
                {
                    title:'Rapid Development',
                    icon:'re.svg',
                    para:'Vaishali OfficePeople all over the globe are living digitally as the lines between physical and digital are converging. It’s fundamental that companies have world-c. Vaishali OfficePeople all over the globe  sdfsdffewfew  wefwerew '
                },
                {
                    title:'Live Reload',
                    icon:'live-reload.svg',
                    para:'Vaishali OfficePeople all over the globe are living digitally as the lines between physical and digital are converging. It’s fundamental that companies have world-c. Vaishali OfficePeople all over the globe  sdfsdffewfew  wefwerew '
                }
            ]
        },
        language:{
            title:'React Native language and frameworks',
            para:'We are a passionate and ambitious set, moving the world forward by challenging all that’s comfortable, creating ideas that live in culture, and forging profound connect..',
            frontend:[
                {
                    key:'Languages',
                    value:'JavaScript, TypeScript'
                },
                {
                    key:'Languages',
                    value:'JavaScript, TypeScript'
                },{
                    key:'Languages',
                    value:'JavaScript, TypeScript'
                },
                {
                    key:'Languages',
                    value:'JavaScript, TypeScript'
                },
                {
                    key:'Languages',
                    value:'JavaScript, TypeScript'
                }
            ],
            backend:[
                {
                    key:'Languages',
                    value:'JavaScript, TypeScript'
                },
                {
                    key:'Languages',
                    value:'JavaScript, TypeScript'
                },{
                    key:'Languages',
                    value:'JavaScript, TypeScript'
                },
                {
                    key:'Languages',
                    value:'JavaScript, TypeScript'
                },
                {
                    key:'Languages',
                    value:'JavaScript, TypeScript'
                }
            ]
        },
        expertise:{
            title:'Our React Native Expertise',
            features:[
                {
                    title:'Blazing Fast Performance and Speed',
                    icon:'blazing.svg'
                },
                {
                    title:'Reusable Code',
                    icon:'reusable-code.svg'
                },
                {
                    title:'Rapid Development',
                    icon:'re.svg'
                },
                {
                    title:'Live Reload',
                    icon:'live-reload.svg'
                }
            ]
        },
        whoUse:{
            title:'Who uses React Native?',
            companies:[

            ]
        },
        product:{

        },
        faqs:[
            {
                que:'Does it Save Money to Build Apps With React Native?',
                ans:'Yes, if you partner with a dedicated React Native mobile app development company for your project, you will invest in a single developer who has to work around a single codebase. This brings the development hours and resource requirement down - lowering with it the development cost'
            },
            {
                que:'Does it Save Money to Build Apps With React Native?',
                ans:'Yes, if you partner with a dedicated React Native mobile app development company for your project, you will invest in a single developer who has to work around a single codebase. This brings the development hours and resource requirement down - lowering with it the development cost'
            },
            {
                que:'Does it Save Money to Build Apps With React Native?',
                ans:'Yes, if you partner with a dedicated React Native mobile app development company for your project, you will invest in a single developer who has to work around a single codebase. This brings the development hours and resource requirement down - lowering with it the development cost'
            }
        ]
    },
    {
        id:'android-app',
        title:'Android App Development',
        subHeading:'A deliberately diverse collective of different outlooks, backgrounds, disciplines and opinions. From the big pictures to the small details, we don’t do things by half.',
        ecosystem:{
            title:'React Native ecosystem. Choose the sure thing.',
            para:'We are a passionate and ambitious set, moving the world forward by challenging all that’s comfortable, creating ideas that live in culture, and forging profound connect.',
        },
        approach:{
            title:'We choose React Native as our go to approach for cross-platform development',
            para:'We are a passionate and ambitious set, moving the world forward by challenging all that’s comfortable, creating ideas that live in culture, and forging profound connect.. We are a passionate and ambitious set, moving the world forward by challenging all that’s comfortable, creating ideas that live in culture, and forging profound connect. We are a passionate and ambitious set, moving the world forward by challenging all that’s comfortable, creating ideas that live in culture, and forging profound connect.',
            features:[
                {
                    title:'Synchronus API',
                    para:'Vaishali OfficePeople all over the globe are living digitally as the lines between physical and digital are converging. It’s fundamental that companies have world-c. Vaishali OfficePeople all over the globe are living digitally as the lines between physical and digital are converging. It’s fundamental that companies have world-c.',
                    icon:'syncronous-api.svg'
                },
                {
                    title:'Fast Performance',
                    para:'Vaishali OfficePeople all over the globe are living digitally as the lines between physical and digital are converging. It’s fundamental that companies have world-c. Vaishali OfficePeople all over the globe are living digitally as the lines between physical and digital are converging. It’s fundamental that companies have world-c.',
                    icon:'fast-performance.svg'
                },
                {
                    title:'Smooth Development',
                    para:'Vaishali OfficePeople all over the globe are living digitally as the lines between physical and digital are converging. It’s fundamental that companies have world-c. Vaishali OfficePeople all over the globe are living digitally as the lines between physical and digital are converging. It’s fundamental that companies have world-c.',
                    icon:'re.svg'
                }
            ]
        },
        reason:{
            title:'Reasons To Choose React Native',
            para:'We are a passionate and ambitious set, moving the world forward by challenging all that’s comfortable, creating ideas that live in culture, and forging profound connect.. We are a passionate and ambitious set, moving the world forward by challenging all that’s comfortable, creating ideas that live in culture, and forging profound connect. We are a passionate and ambitious set, moving the world forward by challenging all that’s comfortable, creating ideas that live in culture, and forging profound connect.',
            features:[
                {
                    title:'Blazing Fast Performance and Speed',
                    icon:'blazing.svg',
                    para:'Vaishali OfficePeople all over the globe are living digitally as the lines between physical and digital are converging. It’s fundamental that companies have world-c. Vaishali OfficePeople all over the globe  sdfsdffewfew  wefwerew '
                },
                {
                    title:'Reusable Code',
                    icon:'reusable-code.svg',
                    para:'Vaishali OfficePeople all over the globe are living digitally as the lines between physical and digital are converging. It’s fundamental that companies have world-c. Vaishali OfficePeople all over the globe  sdfsdffewfew  wefwerew '
                },
                {
                    title:'Rapid Development',
                    icon:'re.svg',
                    para:'Vaishali OfficePeople all over the globe are living digitally as the lines between physical and digital are converging. It’s fundamental that companies have world-c. Vaishali OfficePeople all over the globe  sdfsdffewfew  wefwerew '
                },
                {
                    title:'Live Reload',
                    icon:'live-reload.svg',
                    para:'Vaishali OfficePeople all over the globe are living digitally as the lines between physical and digital are converging. It’s fundamental that companies have world-c. Vaishali OfficePeople all over the globe  sdfsdffewfew  wefwerew '
                }
            ]
        },
        language:{
            title:'React Native language and frameworks',
            para:'We are a passionate and ambitious set, moving the world forward by challenging all that’s comfortable, creating ideas that live in culture, and forging profound connect..',
            frontend:[
                {
                    key:'Languages',
                    value:'JavaScript, TypeScript'
                },
                {
                    key:'Languages',
                    value:'JavaScript, TypeScript'
                },{
                    key:'Languages',
                    value:'JavaScript, TypeScript'
                },
                {
                    key:'Languages',
                    value:'JavaScript, TypeScript'
                },
                {
                    key:'Languages',
                    value:'JavaScript, TypeScript'
                }
            ],
            backend:[
                {
                    key:'Languages',
                    value:'JavaScript, TypeScript'
                },
                {
                    key:'Languages',
                    value:'JavaScript, TypeScript'
                },{
                    key:'Languages',
                    value:'JavaScript, TypeScript'
                },
                {
                    key:'Languages',
                    value:'JavaScript, TypeScript'
                },
                {
                    key:'Languages',
                    value:'JavaScript, TypeScript'
                }
            ]
        },
        expertise:{
            title:'Our React Native Expertise',
            features:[
                {
                    title:'Blazing Fast Performance and Speed',
                    icon:'blazing.svg'
                },
                {
                    title:'Reusable Code',
                    icon:'reusable-code.svg'
                },
                {
                    title:'Rapid Development',
                    icon:'re.svg'
                },
                {
                    title:'Live Reload',
                    icon:'live-reload.svg'
                }
            ]
        },
        whoUse:{
            title:'Who uses React Native?',
            companies:[

            ]
        },
        product:{

        },
        faqs:[
            {
                que:'Does it Save Money to Build Apps With React Native?',
                ans:'Yes, if you partner with a dedicated React Native mobile app development company for your project, you will invest in a single developer who has to work around a single codebase. This brings the development hours and resource requirement down - lowering with it the development cost'
            },
            {
                que:'Does it Save Money to Build Apps With React Native?',
                ans:'Yes, if you partner with a dedicated React Native mobile app development company for your project, you will invest in a single developer who has to work around a single codebase. This brings the development hours and resource requirement down - lowering with it the development cost'
            },
            {
                que:'Does it Save Money to Build Apps With React Native?',
                ans:'Yes, if you partner with a dedicated React Native mobile app development company for your project, you will invest in a single developer who has to work around a single codebase. This brings the development hours and resource requirement down - lowering with it the development cost'
            }
        ]
    }
]


export const developmentTabs = [
    {
        name:'Full-cycle Product Development',
        key:'full-cycle'
    },
    {
        name:'Product Design',
        key:'product-design'
    },
    {
        name:'Full-cycle Product Development',
        key:'full-cycle'
    }
]


export const allDevelopmentSteps =[
    {
        key:'full-cycle',
        title:'Full-cycle Product Development',
        content:'We are part agency, part consultancy. Like some kind of a mythical creature. But with a proces that makes your brand work harder and your teams work better creature. But with a proces that makes your',
        process:[
            'Full-Scale application development life-cycle',
            'Blockchain',
            'Augmented & Virtual Realtiy',
            'Wearables'
        ],
        testimonials:{
            author:'Saurabh, Qnvert',
            para:'We are a passionate and ambitious set, moving the world forward by challenging all that’s comfortable, creating ideas that live in culture, and forging profound connections. For us ou are at the center. hat live in culture, and forging profound connections. For us ou are at the center. hat live in culture, and forging profound connections. For us ou are at the center.'
        },
        background:color.redColor,
        sliderBackground:'full-development-cycle.png',
        slideIndex:0,
        slider:[
            {
                title:'Qnvert',
                para:'A new-age CRM company that helps organisations track, monitor and scale their sales.',
                timeImprovement:'30-40%',
                users:'1,00,000+'
            }
        ]
    },
    {
        key:'product-design',
        title:'Product Design',
        content:'We are part agency, part consultancy. Like some kind of a mythical creature. But with a proces that makes your brand work harder and your teams work better creature. But with a proces that makes your',
        process:[
            'Full-Scale application development life-cycle',
            'Blockchain',
            'Augmented & Virtual Realtiy',
            'Wearables'
        ],
        testimonials:{
            author:'Saurabh, Qnvert',
            para:'We are a passionate and ambitious set, moving the world forward by challenging all that’s comfortable, creating ideas that live in culture, and forging profound connections. For us ou are at the center. hat live in culture, and forging profound connections. For us ou are at the center. hat live in culture, and forging profound connections. For us ou are at the center.'
        },
        background:color.blueColor,
        sliderBackground:'product-design.png',
        slideIndex:0,
        slider:[
            {
                title:'Qnvert',
                para:'A new-age CRM company that helps organisations track, monitor and scale their sales.',
                timeImprovement:'30-40%',
                users:'1,00,000+'
            },
            {
                title:'Scottedil',
                para:'A new-age CRM company that helps organisations track, monitor and scale their sales.',
                timeImprovement:'30-40%',
                users:'1,00,000+'
            }
        ]
    },
]