<!-- <div class="menu-page">
  <div class="head">
    <div class="logo cursor" routerLink="/">
      <img alt='image' src="../../../assets/logo.svg" />
    </div>
    <div class="close-icon" (click)="close()">
      CLOSE
    </div>
  </div>
  <div class="menus fw">
    <li class="hide" routerLink="/portfolio">
      <img alt='image' src="/assets/images_new/menu/w1.png" class="hover-image img-1" />
      <span class="text">WORK</span>
      <img alt='image' src="/assets/images_new/menu/w1.png" class="hover-image img-2" />

    </li>
    <li class="hide" routerLink="/about-us">
      <img alt='image' src="/assets/images_new/menu/w1.png" class="hover-image img-2" />
      <span class="text">ABOUT</span>
      <img alt='image' src="/assets/images_new/menu/w1.png" class="hover-image img-3" />
    </li>
    <li class="hide" routerLink="/services">
      <img alt='image' src="/assets/images_new/menu/w1.png" class="hover-image img-3" />
      <span class="text">SERVICES</span>
      <img alt='image' src="/assets/images_new/menu/w1.png" class="hover-image img-2" />
    </li>
    <li class="hide" routerLink="/blogs">
      <img alt='image' src="/assets/images_new/menu/w1.png" class="hover-image img-3" />
      <span class="text">BLOGS</span>
      <img alt='image' src="/assets/images_new/menu/w1.png" class="hover-image img-2" />
    </li>
    <li class="hide" routerLink="/contact-us">
      <img alt='image' src="/assets/images_new/menu/w1.png" class="hover-image img-3" />
      <span class="text">CONTACT</span>
      <img alt='image' src="/assets/images_new/menu/w1.png" class="hover-image img-2" />
    </li>
  </div>

  <div class="menu-footer">
    <div class="socials">
      <li><a href="https://www.instagram.com/sayhellokilobyte/" target="_blank">Instagram</a></li>
      <li><a href="https://www.facebook.com/HelloKilobyte/" target="_blank">Facebook</a></li>
      <li><a href="https://in.linkedin.com/company/hellokilobyte" target="_blank">Linkedin</a></li>
    </div>
    <div>
      <a class="mail-link">hello@kilobytetech.com</a>
    </div>
  </div>

</div> -->
<div class="menu-page">
  <div class="head">
    <div class="logo cursor companyLogo" routerLink="/">
      <app-logo-kilobyte [fontSize]="24"></app-logo-kilobyte>
    </div>
    <div class="close" (click)="close()">
      <img alt='image' src="/assets/images_new/icons/cross.svg">
    </div>
  </div>
  <div class="menus fw d-sm-block d-md-flex">
    <div class="hhhh">
      <li class="hide" routerLink="/projects" (click)="close();navi('/projects')">
        <img alt='image' src="/assets/images_new/menu/w1.png" class="hover-image img-1" />
        <img alt='image' src="/assets/images_new/menu/w2.png" class="hover-image img-2" />
        <span class="text">WORK</span>

      </li>
      
      <li class="hide" routerLink="/culture" (click)="close();navi('/culture')">
        <img alt='image' src="/assets/images_new/menu/c1.png" class="hover-image img-3" />
        <img alt='image' src="/assets/images_new/menu/c2.png" class="hover-image img-4" />
        <span class="text">CULTURE</span>
      </li>
      <li class="hide" routerLink="/services" (click)="close();navi('/services')">
        <img alt='image' src="/assets/images_new/menu/s1.png" class="hover-image img-3" />
        <img alt='image' src="/assets/images_new/menu/s2.png" class="hover-image img-1" />
        <span class="text">SERVICES</span>
      </li>
      <li class="hide" routerLink="/industry" (click)="close();navi('/industry')">
        <img alt='image' src="/assets/images_new/menu/_U25E_BTE.png" class="hover-image img-3" />
        <img alt='image' src="/assets/images_new/menu/3uSzMekfT.png" class="hover-image img-4" />
        <span class="text">INDUSTRIES</span>
      </li>
      
      <li class="hide" routerLink="/blogs" (click)="close();navi('/blogs')">
        <img alt='image' src="/assets/images_new/menu/b1.png" class="hover-image img-3" />
        <img alt='image' src="/assets/images_new/menu/b2.png" class="hover-image img-4" />
        <span class="text">BLOGS</span>
      </li>

      <li class="hide" routerLink="/contact-us" (click)="close();navi('/contact-us')">
        <img alt='image' src="/assets/images_new/menu/cu1.png" class="hover-image img-3" />
        <img alt='image' src="/assets/images_new/menu/cu2.png" class="hover-image img-1" />
        <span class="text">CONTACT</span>
      </li>
    </div>

    <div class="righhtsideofmenu">

      <p class="mb-2">Our Products:</p>

        <p class="prodss" routerLink="/tools">ESignByte</p>
        <p class="prodss"><a href="https://signup.whatsbyte.com/signup" target="_blank" style="text-decoration: none; color: unset !important;">Whatsbyte</a></p>
        <p class="prodss"><a href="https://surveybyte.kilobyte.live" target="_blank" style="text-decoration: none; color: unset !important;">SurveyByte</a></p>
        <p class="prodss"><a href="https://app.proposalbyte.com" target="_blank" style="text-decoration: none; color: unset !important;">ProposalByte</a></p>
        <p class="prodss"><a href="https://qrbyte.kilobyte.live" target="_blank" style="text-decoration: none; color: unset !important;">BizQRByte</a></p>

    </div>
  </div>

  <div class="menu-footer">
    <!-- <div class="socials">
      <li><a href="https://www.instagram.com/sayhellokilobyte/" target="_blank">Instagram</a></li>
      <li><a href="https://in.linkedin.com/company/hellokilobyte" target="_blank">Facebook</a></li>
      <li><a href="https://in.linkedin.com/company/hellokilobyte" target="_blank">Linkedin</a></li>
    </div> -->
    <div class="social-icons">
      <a href="https://dribbble.com/HelloKilobyte" target="_blank">
        <img alt='image' src="/assets/images_new/icons/football.svg">
      </a>
      <a href="https://www.behance.net/HelloKilobyte" target="_blank">
        <img alt='image' src="/assets/images_new/icons/be.svg">
      </a>
      <a href="https://www.instagram.com/sayhellokilobyte/" target="_blank">
        <img alt='image' src="/assets/images_new/icons/insta.svg">
      </a>
      <a href="https://in.linkedin.com/company/hellokilobyte" target="_blank">
        <img alt='image' src="/assets/images_new/icons/linkedin.svg">
      </a>
      <a href="mailto:hello@kilobytetech.com" target="_blank">
        <img alt='image' src="/assets/images_new/icons/mail.svg">
      </a>
    </div>
    <!-- <div class="emailSignatureTool">
      <div class="check">Check out our latest &nbsp;</div>
      <div class="name" routerLink="/tools">
        Email Signature Tool
        <hr class="ethr">
      </div>
    </div> -->
    <div>
      <a class="mail-link" href="mailto:hello@kilobytetech.com">hello@kilobytetech.com</a>
    </div>
  </div>

</div>