<div class="header">
    <app-header-new [textColor]="colorTextHome"></app-header-new>
</div>
<div id="light">
    <img alt='image' src="/assets/images_new/services/torch.svg" >
</div>
<div class="body serviceBodyHai" #mainBlock [ngStyle]="{backgroundColor: backgroundColorHome, color: colorTextHome}">
    <div class="banner scrolly" #banner>
        <div class="b1">
            <div class="weDo">
                <p>What we Do</p>
                <img alt='image' src="/assets/images_new/services/starHome.svg" width="88" height="88" >
            </div>
            <div class="descWeDo">
                We are a full time creative design and development 
                agency who thinks the <span class="borderText">world</span> can become a <span class="borderText">better</span> place 
                through <span class="primary">design</span>
            </div>
        </div>
        <div class="b2">
            <img alt='image' src="/assets/images_new/services/text.png" class="star_rotate" >
            <img alt='image' src="/assets/images_new/icons/star.svg" class="star" height="79px" width="79px" >
        </div>
        <div class="b3 row">
            <div class="col-md-4">
                <div class="headB3">
                    Our Services
                </div>
            </div>
            <div class="col-md-4">
                <div class="headB3">
                    Design
                </div>
                <hr class="line">
                <div class="listItem">UX Research </div>
                <div class="listItem">Ideation</div>
                <div class="listItem">Sitemap</div>
                <div class="listItem">Visual Design</div>
                <div class="listItem">Micro Intractions</div>
            </div>
            <div class="col-md-4">
                <div class="headB3">
                    Development
                </div>
                <hr class="line">
                <div class="listItem">UX Research </div>
                <div class="listItem">Ideation</div>
                <div class="listItem">Sitemap</div>
                <div class="listItem">Visual Design</div>
                <div class="listItem">Micro Intractions</div>
            </div>
        </div>
        <div class="b4">
            <div class="anim">   
                <hr class="lineSemi">
            </div>
            <div class="listItem row">
                <div class="col-md-2 num">
                    01
                </div>
                <div class="col-md-4 title">
                    Branding and Strategy 
                </div>
                <div class="col-md-6 desc">
                    We beleive we can make a world where 
                    experiences are goverend by design 
                    and infuenced by feedbacks. We try to 
                    syntesize design and energy into meaningful 
                    experiences and try to reticulate smooth 
                    userflow by adding micro - 
                    interactions and light animations.
                </div>
            </div>
            <div class="listItem row">
                <div class="col-md-2 num">
                    02
                </div>
                <div class="col-md-4 title">
                    User Research
                </div>
                <div class="col-md-6 desc">
                    Our every step of the entire design step is based upon firm logic and research . The first step of the design process includes desfining the competitors
                </div>
            </div>
            <div class="listItem row">
                <div class="col-md-2 num">
                    03
                </div>
                <div class="col-md-4 title">
                    Visual Design
                </div>
                <div class="col-md-6 desc">
                    One of the most important part of our process are the visuals. 
                    There is a solid reason as to why we select a particular color or why we go with a particular theme, be it graphic or illustration based or simple type based. Based upon the nature of the product we choose the font and color schemes.
                </div>
            </div>
            <div class="listItem row">
                <div class="col-md-2 num">
                    04
                </div>
                <div class="col-md-4 title">
                    Development
                </div>
                <div class="col-md-6 desc">
                    One of the most important part of our process are the visuals. 
                    There is a solid reason as to why we select a particular color or why we go with a particular theme, be it graphic or illustration based or simple type based. Based upon the nature of the product we choose the font and color schemes
                </div>
            </div>
        </div>
    </div>

    <div class="inds scrolly" #inds>        
        <div (click)="moveUp()" class="upss" style="cursor: pointer;display:none">{{activeIndSlide}}</div>
        <div (click)="moveDown()" class="downss" style="cursor: pointer;display:none">{{activeIndSlide}}</div>
        <div class="b5 sliderx" [ngStyle]="{'maxHeight': indDivHeight+'px','minHeight': indDivHeight+'px'}">
                        
            <div class="dataIndSlide">
                <div>
                    <div class="titleD">
                        Our Projects
                    </div>
                    <div class="heading">
                        {{industries[activeIndSlide]?.head}}
                    </div>
                    <div class="desc">
                        {{industries[activeIndSlide]?.info}}
                    </div>
                </div>
            </div>
            <div class="b51" *ngFor="let item of industries;let i=index" #ind 
                [ngStyle]="{
                    'maxHeight': indDivHeight+'px',
                    'minHeight': indDivHeight+'px',
                    'bottom': i==activeIndSlide-1?indDivHeight+'px':i==activeIndSlide?0+'px':(-1*indDivHeight)+'px'
                }"
            >                
                <div class="data">
                    <div class="titleD">
                        Our Projects
                    </div>
                    <div class="heading">
                        {{'a'}}
                    </div>
                    <div class="desc">
                        {{'b'}}
                    </div>
                </div>
                <img alt='image' [src]="item?.image" >
            </div>
        </div>
    </div>
    
    <div class="para scrolly" #para>
        <div class="paragraph">
            <!-- <div class="blank">&nbsp;</div>We are a strategic partner to our clients. 
            We will help you to ideate, <span class="black">design</span> and implement your product 
            from conception to iterative <span class="black">development</span> support, 
            always taking the initiative and working proactively. -->

            <span class="black">You dream, We create.</span> From small startups to multinational companies we help people 
            turn <span class="black">ideas to reality</span> through detailed strategic process and planning, exploring 
            dimensions resulting in closing the gap between you and your customers.
        </div>
    </div>

    <div class="work scrolly" #work>
        <div class="workDiv">
            <div class="headWork">
                <div class="titleHead">What we Do</div>
                <div class="descHead">
                    People love to work with us . We work with some awesomely talented people
                </div>
            </div>
            <div class="workBody">
                <section class="marquee-container">
                    <div class="marquee" data-speed="-1">
                        <div class="inner">
                            <p>
                                <span *ngFor="let client of allClients?.first;let i=index">
                                    <img alt='image' [src]="client.color"  class="color">
                                    <img alt='image' [src]="client.gray"  class="gray">
                                </span>
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>

    <div class="contact container scrolly" #contactUs>
        <app-footer-new></app-footer-new>
    </div>
</div>