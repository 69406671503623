import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private http: HttpClient
  ) { }

  login(data) {
    return this.http.post(`${environment.API_URL}/Member/Login`, data);
  }

  getContactUs(params) {
    return this.http.get(`${environment.API_URL}/contactus`, {params:params});
  }

  getProjects() {
    return this.http.get(`${environment.API_URL}/Project`);
  }

  getProjectDetails(id) {
    return this.http.get(`${environment.API_URL}/Project/${id}`);
  }

  createProject(data) {
    return this.http.post(`${environment.API_URL}/Project`, data);
  }

  updateProject(data, id) {
    return this.http.put(`${environment.API_URL}/Project/${id}`, data);
  }

  getBlogs(params) {
    return this.http.get(`${environment.API_URL}/Blog`,{params:params});
  }

  getEmailSignatureData(params) {
    return this.http.get(`${environment.API_URL}/emailSignature`,{params:params});
  }

  getBlogDetails(name) {
    return this.http.get(`${environment.API_URL}/Blog/${name}`);
  }

  createBlog(data) {
    return this.http.post(`${environment.API_URL}/Blog`, data);
  }

  updateBlog(data, id) {
    return this.http.put(`${environment.API_URL}/Blog/${id}`, data);
  }

  uploadImage(data) {
    return this.http.post(`${environment.API_URL}/Member/UploadImages`, data);
  }

  forgotPassword(data) {
    return this.http.put(`${environment.API_URL}/Member/ForgotPassword`, data);
  }

  resetPassword(data, params) {
    return this.http.put(`${environment.API_URL}/Member/ResetPassword`, data, { params: params });
  }

  contactUs(data) {
    // return this.http.post(`${environment.API_URL}/Member/ContactUs`, data);
    return this.http.post(`${environment.API_URL}/contactus`, data);
  }

  blogs(){
    return this.http.get(`https://finwithbharat.com/wp-json/wp/v2/posts`);
  }
  blogsDetail(id){
    return this.http.get(`https://finwithbharat.com/wp-json/wp/v2/posts/`+ id);
  }

  getAuthorName(url){
    return this.http.get(url);
  }
}
